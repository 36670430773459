<template>
  <v-container fluid>
    <v-row no-gutters class='mt-2'>
      <v-card tile flat color='white' class='wd-100'>
        <v-timeline dense>
          <v-timeline-item v-for='(version, i) in versions' :key='i' color='#239ed7'>
            <zero-md>
              <script type='text/markdown'>
                {{version}}
              </script>
            </zero-md>
          </v-timeline-item>
        </v-timeline>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'ReleaseNotes',
    data: function () {
      return {
        versions: [],
      };
    },
    mounted: function () {
      this.getData();
    },
    methods: {
      getData: async function () {
        const text = require('./release-notes.md');
        this.versions = text.split('--');
        this.$forceUpdate();
      },
    },
  };
</script>
